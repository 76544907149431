import { useUser } from "@auth0/nextjs-auth0";
import moment from "moment";
import useSWR, { mutate } from "swr";
import useSWRInfinite from "swr/infinite";
import { Button } from "../components/button";

async function subscriptionsFetcher(url) {
  const response = await fetch(url);
  if (!response.ok) {
    return [];
  }
  const data = await response.json();
  return data || [];
}

async function moreEventsFetcher(url) {
  const response = await fetch(url);
  return await response.json();
}

export default function Launches({ events = [], nextURL }) {
  const { user } = useUser();

  // TODO: Don't use `let`
  let { data: subscriptions, error } = useSWR(
    "/api/subscriptions/my-subscriptions",
    subscriptionsFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  // TODO: handle error
  subscriptions = subscriptions || [];

  const getKey = (_pageIndex, previousPageData) => {
    if (!previousPageData) {
      return nextURL;
    }
    if (!previousPageData.next) {
      return null;
    }
    return previousPageData.next;
  };

  const {
    data: moreEventsData,
    size,
    setSize,
    isValidating,
  } = useSWRInfinite(getKey, moreEventsFetcher);

  // TODO: this is totally busted. concat doesn't have side effects, so the
  // reduce does nothing here.
  const moreEvents = moreEventsData
    ? moreEventsData.reduce((previous, current) => {
        current.results.concat(previous.results);
        return current;
      })
    : { next: nextURL, results: [] };

  events = events.concat(moreEvents.results);

  return (
    <div className="outer">
      <div className="inner" style={{ maxWidth: 900 }}>
        <h1 style={{ marginTop: 40 }}>Upcoming Launches</h1>
        <p>
          We're building a list of launches from public sources that you can
          easily connect to Zapier.
        </p>
        <ul className="launches">
          {events
            .filter((x) => x.fields.id)
            .map((event) => (
              <li
                className="launch"
                data-event={JSON.stringify(event)}
                style={{ position: "relative" }}
                key={event.fields.id}
              >
                <h3>
                  {event.fields.rocket_name} &mdash;{" "}
                  {event.fields.mission_name || `Upcoming Launch`}
                </h3>
                {user && subscriptions && !subscriptions.includes(event.id) && (
                  <Button
                    style={{ position: "absolute", top: 20, right: 20 }}
                    onClick={() => {
                      fetch(`/api/subscriptions/add/${event.id}`, {
                        method: "POST",
                      })
                        .then((r) => r.text())
                        .then((done) => {
                          mutate("/api/subscriptions/my-subscriptions", [
                            ...subscriptions,
                            event.id,
                          ]);
                        });
                    }}
                  >
                    Subscribe
                  </Button>
                )}
                {user &&
                  subscriptions &&
                  (subscriptions.includes(event.id) ? (
                    <Button
                      style={{
                        position: "absolute",
                        top: 20,
                        right: 20,
                        background: "#666",
                      }}
                      onClick={() => {
                        fetch(`/api/subscriptions/remove/${event.id}`, {
                          method: "POST",
                        })
                          .then((r) => r.text())
                          .then((done) => {
                            mutate(
                              "/api/subscriptions/my-subscriptions",
                              subscriptions.filter((x) => x !== event.id)
                            );
                          });
                      }}
                    >
                      Unsubscribe
                    </Button>
                  ) : (
                    ""
                  ))}
                <sub>{moment(event.fields.launch_start).fromNow()}</sub>
                <p>
                  {event.fields.description || "No mission description found"}
                </p>
              </li>
            ))}
        </ul>

        <div className="pagination-wrapper">
          {moreEvents.next ? (
            <Button
              disabled={isValidating}
              onClick={() => {
                setSize(size + 1);
              }}
            >
              Load More
            </Button>
          ) : (
            ""
          )}
        </div>
      </div>
      <style jsx>{`
        .pagination-wrapper {
          text-align: center;
          padding: 15px 0px 25px;
        }
        .outer {
          width: 100%;
          background: url("/bg.svg");
          background-repeat: no-repeat;
          background-attachment: fixed;
          background-position: 100% 100%;
        }
        .inner {
          background: #ffffffee;
          padding: 0 20px;
          margin: auto;
          margin-bottom: 30px;
        }
        @media (min-width: 900px) {
          .inner {
            border-radius: 10px;
          }
        }
        .inner > p,
        .inner > h1 {
          padding: 0;
          margin: auto;
          padding-bottom: 1em;
        }
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        li {
          display: block;
        }
        h3 {
          margin: 0;
          display: flex;
          align-items: center;
        }
        sub {
          color: #666;
          font-size: 1rem;
        }
        .launch {
          width: 100%;
          padding: 25px;
          background: var(--color-bg);
          border: 1px solid rgb(0 0 0 / 20%);
          box-shadow: 0px 5px 10px rgb(0 0 0 / 10%);
          background-clip: padding-box;
          box-sizing: border-box;
          border-radius: var(--radius-button);
          display: block;
          margin-bottom: 20px;
        }
      `}</style>
    </div>
  );
}

export async function getStaticProps() {
  const url = new URL("https://api.zapier.space/api/events");
  url.searchParams.set("limit", "10");
  url.searchParams.set("sort_by", "launch_start");
  url.searchParams.set("upcoming", "true");
  const resp = await fetch(url.href);
  const { results = [], next = null } = await resp.json();
  return {
    props: {
      events: results,
      nextURL: next,
    },
    revalidate: 5,
  };
}
